import React, { Component } from 'react';
import '../../../asset/css/common/text.css';


class Text extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            hasValue: props.value ? true : false,
        }
        this._handleTextValue = this._handleTextValue.bind(this);
    }

    toggleActiveState = () => {
        this.setState({
            active: !this.state.active
        });
    }

    _handleTextValue (e) {
        let value = e.target.value;
        
        this.setState({
            hasValue : value.trim() !== "" ? true : false,
        });

        this.toggleActiveState()
    }

    render() {
        //console.log(this._handleTextValue());
        return (
            <div>
                <label className="label">{ this.props.label || '' }</label>
                <div className={`TextArea-label ${this.state.active ? ' active' : ''} ${!this.props.icon ? ' full-width' : ''}`}>
                    <textarea 
                        value={this.props.value} type={this.props.type || "text"} 
                        onFocus={this.toggleActiveState} onBlur={this._handleTextValue} 
                        onChange={this.props.onChange} 
                        name={this.props.name} 
                        placeholder={this.props.placeholder}
                    ></textarea>
                    <span className="icon">
                        { this.props.icon ? <img src={this.props.icon} alt="user" width="18" height="20" /> : '' }
                    </span>
                    <small className="Helper">
                        <font>{ this.props.error || ""}</font>
                    </small>
                </div>
            </div>
        );
    }
}

export default Text;