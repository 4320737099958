import React, { Component } from 'react';
import '../../../asset/css/common/loader.css';

class EllipsisLoader extends Component {
    render() {
        return (
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        );
    }
}

export default EllipsisLoader;