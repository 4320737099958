import React, { Component } from 'react';
import '../../asset/css/modal.css';
import Checkmark from '../../asset/images/green-checkmark.svg';

class Slider extends Component {
    render(){

        return (
            <div className="modal-background">
                <div className="modal">
                    <div className="modal-head">
                        <h4 className="modal-heading-text">{ this.props.heading || "Thank You"}</h4>
                    </div>
                    <div className="modal-body">
                        <img src={ this.props.image || Checkmark} alt="Check Mark" />

                        <p>{ this.props.body || "successful."}</p>
                        <p>{ this.props.footer || "E-settlement"}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider;
