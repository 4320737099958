import http from '../http';

export const Resend = async (data) => {
    try {
        return await http.post('auth/email/resend', data)
    }  catch (err) {
        throw  err;
    }
}

export const Verify = async (data) => {
    //console.log(data)
    try {
        return await http.post('auth/email/verify/' + data.id + '/' + data.hash + '?expires=' + data.expires + '&signature=' + data.signature)
    }  catch (err) {
        throw  err;
    }
}