import React, { Component } from 'react';
import Swiper from 'swiper';
import '../../asset/css/plugins/swiper.min.css';
import '../../asset/css/swiper-overides.css';
import Container from 'react-bootstrap/Container';
import WhiteAfricaLogo from '../../asset/images/white-africa-logo.svg';
// import BlueAfricaLogo from '../../asset/images/blue-africa-logo.svg';

class Slider extends Component {
    
    componentDidMount() {
        new Swiper ('.swiper-container', {
          autoplay: {
            delay: 5000,
          },

          // Optional parameters
          direction: 'horizontal',
          loop: true,
      
          // And if we need scrollbar
          scrollbar: {
            el: '.swiper-scrollbar',
          },

          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
    
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
    
        });

      }

    render(){
        return (
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    <div className="slider-1 swiper-slide d-flex align-items-center">
                        <Container>
                            <div className="slider-text-section">
                                <h4 className="yellow-slider-text text-uppercase">Enabling</h4>
                                <div className="african-map-flex-col d-inline-flex flex-column">
                                    <h2 className="white-slider-text">Financial Inclusion</h2>
                                    <div className="africa-section d-flex align-items-center justify-content-end">
                                        <img src={WhiteAfricaLogo} alt="Africa logo" />
                                        <h4 className="africa-text text-uppercase">In Africa</h4>
                                    </div>
                                </div>
                                
                                <p className="slider-description">We are providing access to financial services, creating jobs and transforming businesses across Africa through our agent network and E-Payment solutions.</p>
                            </div>
                        </Container>
                    </div>

                    <div className="slider-2 swiper-slide d-flex align-items-center">
                        <Container>
                            <div className="slider-text-section">
                                <h4 className="yellow-slider-text text-uppercase">Creating Jobs Through</h4>
                                <div className="african-map-flex-col d-inline-flex flex-column">
                                    <h2 className="white-slider-text">Agency Banking</h2>
                                    <div className="africa-section d-flex align-items-center justify-content-end">
                                        <img src={WhiteAfricaLogo} alt="Africa logo" />
                                        <h4 className="africa-text text-uppercase">In Africa</h4>
                                    </div>
                                </div>
                                
                                <p className="slider-description">We are providing access to financial services, creating jobs and transforming businesses across Africa through our agent network and E-Payment solutions.</p>
                            </div>
                        </Container>
                    </div>

                    <div className="slider-3 swiper-slide d-flex align-items-center">
                        <Container>
                            <div className="slider-text-section">
                                <h4 className="yellow-slider-text text-uppercase">Helping</h4>
                                <div className="african-map-flex-col d-inline-flex flex-column">
                                    <h2 className="white-slider-text">Businesses Grow</h2>
                                    <div className="africa-section d-flex align-items-center justify-content-end">
                                        <img src={WhiteAfricaLogo} alt="Africa logo" />
                                        <h4 className="africa-text text-uppercase">In Africa</h4>
                                    </div>
                                </div>
                                
                                <p className="slider-description">We are providing access to financial services, creating jobs and transforming businesses across Africa through our agent network and E-Payment solutions.</p>
                            </div>
                        </Container>
                    </div>
                </div>
                
                <Container className="swiper-pagination-container">
                    <div className="swiper-pagination"></div>
                </Container>

            </div>
        );
    }
}

export default Slider;
