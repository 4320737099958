import React, { Component } from "react";

import FooterLogo from "../../asset/images/footerlogo.svg";
import Location from "../../asset/images/location.svg";
import Envelope from "../../asset/images/envelope.svg";
import Linkedin from "../../asset/images/linkedin.svg";
import Facebook from "../../asset/images/facebook.svg";
import Twitter from "../../asset/images/twitter.svg";
import Copyright from "../../asset/images/copyright.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../asset/css/footer.css";
import ISMSPDF from "../../asset/pdfs/ISMS05001 ESettlement Information Security Summary Policy.pdf";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggle: false,
			toggle2: false,
			toggle3: false,
		};
		this.toggleFooterNav = this.toggleFooterNav.bind(this);
		this.toggleFooterNav2 = this.toggleFooterNav2.bind(this);
		this.toggleFooterNav3 = this.toggleFooterNav3.bind(this);
	}

	toggleFooterNav = () => {
		this.setState({ toggle: !this.state.toggle });
	};

	toggleFooterNav2 = () => {
		this.setState({ toggle2: !this.state.toggle2 });
	};

	toggleFooterNav3 = () => {
		this.setState({ toggle3: !this.state.toggle3 });
	};

	resize = () => {
		window.addEventListener("resize", function (event) {
			console.log(
				document.body.clientWidth + " wide by " + document.body.clientHeight + " high"
			);
		});
	};

	render() {
		return (
			<div className="footer">
				<Row className="top-footer">
					<Col md={12} lg={4} className="compamy-desc">
						<h6>
							<img src={FooterLogo} alt="logo" />
						</h6>

						<p className="company-desc-text text-white">
							E-Settlement Limited is an independent, private sector led, limited
							liability company located in Lagos, Nigeria focused on providing
							Innovative, Dependable, Easy-to-use, Affordable and Secure payment
							solutions to everyday financial problems in Africa.
						</p>
					</Col>
					<Col xs={12} md={6} lg={2}>
						<div className="footer-section quick-links-col">
							<div className="footer-section-title">
								<h6 className="text-white">Quick Links </h6>

								<div
									className="footer-dropdown-toggle"
									onClick={this.toggleFooterNav}
								>
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
									</svg>
								</div>
							</div>
							<ul
								className={`footer-nav nav-links ${
									this.state.toggle ? "d-block" : "d-none"
								}`}
							>
								<li className="footer-nav-items">
									<a href="http://paycentreafrica.com/" target="blank">
										PayCentre
									</a>
								</li>
								<li className="footer-nav-items">
									<a href="http://cashenvoy.com/" target="blank">
										CashEnvoy
									</a>
								</li>
								<li className="footer-nav-items">
									<a href="http://paypad.com.ng/" target="blank">
										PayPad
									</a>
								</li>
								<li className="footer-nav-items">
									<a href="/contact">Contact Us</a>
								</li>
								<li className="footer-nav-items">
									<a href="/career">Careers</a>
								</li>
								<li className="footer-nav-items">
									<a href="http://blog.esettlementgroup.com/" target="blank">
										Blog
									</a>
								</li>
								{/* <li className="footer-nav-items"><a href="#!">FAQs</a></li> */}
							</ul>
						</div>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<div className="footer-section">
							<div className="footer-section-title">
								<h6 className="text-white">Get In Touch </h6>

								<div
									className="footer-dropdown-toggle"
									onClick={this.toggleFooterNav2}
								>
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
									</svg>
								</div>
							</div>
							<div
								className={`footer-nav contact ${
									this.state.toggle2 ? "d-block" : "d-none"
								}`}
							>
								<div className="footer-contact">
									<img src={Location} alt="location" />
									<p className="text-white">
										13A, Hughes Avenue, Alagomeji, Yaba, Lagos
									</p>
								</div>

								<div className="footer-contact email pb-4">
									<img src={Envelope} alt="envelop" />
									<p className="text-white">contact@esettlementgroup.com</p>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} md={0} lg={1}></Col>
					<Col xs={12} md={12} lg={2}>
						<div className="footer-section footer-section-last">
							<div className="footer-section-title">
								<h6 className="text-white">Follow Us</h6>

								<div
									className="footer-dropdown-toggle"
									onClick={this.toggleFooterNav3}
								>
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
									</svg>
								</div>
							</div>
							<div
								className={`footer-nav socials ${
									this.state.toggle3 ? "d-flex" : "d-none"
								}`}
							>
								<div className="social-logo">
									<a
										href="https://www.linkedin.com/company/esettlement"
										target="blank"
									>
										<img src={Linkedin} alt="linkedIn" />
									</a>
								</div>
								<div className="social-logo">
									<a
										href="https://www.facebook.com/paycentreafrica"
										target="blank"
									>
										<img src={Facebook} alt="facebook" />
									</a>{" "}
								</div>
								<div className="social-logo">
									<a href="https://twitter.com/paycentreafrica" target="blank">
										<img src={Twitter} alt="twitter" />
									</a>
								</div>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="bottom-footer">
					<Col xs={12} lg={6} className="bottom-div">
						<p className="text-white">
							{" "}
							Copyright
							<img src={Copyright} alt="copyright" /> 2020 Electronic Settlement
							Limited{" "}
						</p>
					</Col>
					<Col lg={6}>
						<ul className="secondary-footer-nav">
							<li>
								<a target="_blank" rel="noreferrer noopener" href={ISMSPDF}>
									ISMS Policy
								</a>
							</li>
							<li>
								<a href="/payment-terms-condition">Payment Service Terms and Conditions</a>
							</li>
							<li>
								<a href="/refund-policy">Refund & Cancellation Policy</a>
							</li>
							<li>
								<a href="/privacy-policy">Privacy Policy</a>
							</li>
							<li>
								<a href="/terms-condition">Terms & Conditions</a>
							</li>
						</ul>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Footer;
