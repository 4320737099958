import http from './http';


export const FetchApplication = async () => {
    try {
        return await http.get('work/application')
    }  catch (err) {
        throw  err;
    }
}
export const FetchMyApplication = async () => {
    try {
        return await http.get('work/application/me')
    }  catch (err) {
        throw  err;
    }
}

export const GetNextStagePublic = async (id) => {
    try {
        return await http.post('work/application/next/' + id )
    }  catch (err) {
        throw  err;
    }
}

export const GetNextStage = async (id) => {
    try {
        return await http.post('work/application/admin/next/' + id )
    }  catch (err) {
        throw  err;
    }
}

export const Progress = async (id, data) => {
    try {
        return await http.post('work/application/progress/' + id , data)
    }  catch (err) {
        throw  err;
    }
}

export const FetchComment = async user_id => {
    const url = 'work/application/comment' + (typeof user_id === 'undefined' ? '' : '/' + user_id);
    try {
        return await http.get(url)
    }  catch (err) {
        throw  err;
    }
}

export const AddComment = async (user_id, data) => {
    const url = 'work/application/comment' + (typeof user_id === 'undefined' ? '' : '/' + user_id);
    try {
        return await http.post(url, data)
    }  catch (err) {
        throw  err;
    }
}

export const DeleteComment = async user_id => {
    const url = 'work/application/comment/delete' + (typeof user_id === 'undefined' ? '' : '/' + user_id);
    try {
        return await http.get(url)
    }  catch (err) {
        throw  err;
    }
}
export const CvContent = async authToken => {
    const url = 'cv/parse'
    try {
        return await http.get(url)
    }  catch (err) {
        throw  err;
    }
}