import {combineReducers} from 'redux';
import app from './app'
import state from './state'
import user from './user'
import job from './job'
import application from './application'

const reducers = combineReducers ({
   app,
   state,
   user,
   job,
   application
});

export default reducers