import React, { Component } from 'react';
import '../../asset/css/personaldetails.css';
import Page from '../templates/Page';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav'
import Input from '../components/common/labelled-input';
import Select from '../components/common/labelled-select';
import Text from '../components/common/labelled-text';
import DateTime from '../components/common/labelled-date';
import Calender from '../../asset/images/calender.svg'
import LinkedIn from '../../asset/images/linkin.svg'
import Pen from '../../asset/images/pen.svg'
import avatarIcon from '../../asset/images/avater.png'
import cameraIcon from '../../asset/images/cameraIcon.png'
import Delete from '../../asset/images/delete.svg'
import unsuccessful from '../../asset/images/unsuccessful.png'
import Validator from '../../util/Validator';
import * as moment from 'moment';
import Button from '../components/common/button';
import { Form, Spinner } from 'react-bootstrap';
import { CreateCV, FetchCV, UploadCVFile, UploadAvatarFile, DeleteComponent } from '../../services/profile';
import { Apply } from '../../services/job';
import { connect } from 'react-redux';
import Checkmark from '../../asset/images/green-checkmark.svg';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../components/modal.js'
import store from '../../redux/store';
import { CvContent } from '../../services/application';
// import url from '../../asset/sample.pdf'

class personaldetails extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      modal: {
        
      },
      stage: window.location.pathname.split('/')[1],
      loading: false,
      editProfile: false,
      editSchool: false,
      editExperience: false,
      editProject: false,
      editSkill: false,
      school: {},
      schools: [],
      experience: {},
      experiences: [],
      skill: {},
      skills: [],
      project: {},
      projects: [],
      error: {},
      errors: {
        school: {},
        experience: {},
        project: {},
        skill: {}
      },
      file: null,
      selectedFile: null
    }

    this.imageUploader = React.createRef();
    this.uploadedImage = React.createRef();
    this.resumeUploader = React.createRef()
    this.uploadedResume = React.createRef()


    this._editProfileClick = this._editProfileClick.bind(this);
    this._addComponent = this._addComponent.bind(this);
    this._handleOnChange = this._handleOnChange.bind(this);
    this._handleOnChangeDate = this._handleOnChangeDate.bind(this)
    this._editComponent = this._editComponent.bind(this)
    this._deleteComponent = this._deleteComponent.bind(this)
    this._hideForm = this._hideForm.bind(this)
    this._handleNavSelect = this._handleNavSelect.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._handleChangeDate = this._handleChangeDate.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
    this._applyClick = this._applyClick.bind(this)
    this._handleUpload = this._handleUpload.bind(this)
    this._handleImageUpload = this._handleImageUpload.bind(this)
    // this.showAccess = this.showAccess.bind(this)
  }

  // showAccess() {
  //   const authToken = localStorage.getItem("rt")
  //   CvContent(authToken).then(res => {
  //     let contents = res.data.data;
  //     console.log(contents)
  //     let Educations = contents.Education
  //     let Project = contents.Project.Description
  //     let Certificate = contents.Certificate.Description
  //     let skill = contents.Skills.Name
  //     let Experiences = contents.Experience
  //     // const Educations = Object.entries(Education)
  //     const skills = Object.entries(skill)
  //     const Projects = Object.entries(Project);
  //     const Certificates = Object.entries(Certificate)
  //     // const Experiences = Object.entries(Experience);
  //     // console.log(Experiences)
      
  //     // this.setState({
  //     //   skills: skills
  //     // })

  //       // skills.map((skill => {
  //       //   this.state.skills.name = skills
  //       //   var textfield = document.createElement("input");
  //       //   textfield.type = "text"; textfield.value = this.state.skills.name;
  //       //   document.getElementById('testinput').appendChild(textfield);
  //       //    console.log(skill)
  //       // }))
  
  //     this.setState({
  //       skills: skills.map(skill => {
  //         return {
  //           name: skill
  //         }
  //       })
  //     })
  //     this.setState({
  //       projects: Projects.map(Project => {
  //         return {
  //           description: Project
  //         }
  //       })
  //     })
  //     this.setState({
  //       Certificates: Certificates.map(Certificate => {
  //         return {
  //           description: Certificate
  //         }
  //       })
  //     })
  //     this.setState({
  //       schools: Object.keys(Educations).map((key) => {
  //         console.log(Educations.Date)
  //         console.log(Educations.School)
  //         return {
  //           start_date: Educations.Date,
  //           name: Educations.School
  //           // degree: school.attended.level,
  //           // course: school.attended.course,
  //           // end_year: school.Date
  //         }
  //       })
  //     })
  //     this.setState({
  //        experiences: Object.keys(Experiences).map((key) =>{
  //          return {
  //           description: Experiences.Description,
  //           company: Experiences.Company,
  //           start_date: Experiences.Date
  //          } 
  //       })
  //     })
  //     // console.log(experience.company) 
     
  //     // this.setState({
  //     //   Experiences.map(Experience => {
  //     //     console.log(Experience.Date)
  //     // })
  //   // })
  //   // console.log(description)
  //   }).catch(err => {
  //     console.log(err)
  //   }).finally(() => {
  //     this.setState({
  //       loading: false
  //     })
  //   })
  // }
  componentDidMount() {
    this.setState({
      loading: true
    })
    FetchCV().then(res => {
      let cv = res.data;
      console.log(cv)
      this.setState({
        email: cv.email,
        ...cv.profile,
        schools: cv.schools.map(school => {
          return {
            name: school.name,
            address: school.address,
            degree: school.attended.level,
            course: school.attended.course,
            end_year: school.attended.end_year
          }
        }),
        experiences: cv.experiences,
        skills: cv.skills,
        projects: cv.projects,
        cv: cv.cv,
      })
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.setState({
        loading: false
      })
    })
  }

  leaveOpen = [""];
  // leaveOpen = ["skill"];


  _handleOnChange(e, type) {
    this.setState({
      [type]: {
        ...this.state[type],
        [e.target.name]: e.target.value || e.currentTarget.value
      }
    });
  }

  _handleOnChangeDate(e, name, type) {

    let date = null
    const dateFormat = 'DD-MM-YYYY';
    if (!e._isValid) {

      date = moment(e).format(dateFormat)

      if (!moment(date, dateFormat, true).isValid()) {
        return
      }

    } else {
      date = e.format(dateFormat)
    }

    this.setState({
      [type]: {
        ...this.state[type],
        ...{ [name]: date }
      }
    });
  }

  _handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      //file: URL.createObjectURL(e.target.files[0])
    })
    // console.log(e.target.files[0])
  }

  _handleChangeDate(e, name) {

    let date = null
    const dateFormat = 'DD-MM-YYYY';
    if (!e._isValid) {

      date = moment(e).format(dateFormat)

      if (!moment(date, dateFormat, true).isValid()) {
        return
      }

    } else {
      date = e.format(dateFormat)
    }
    console.log(date)
    this.setState({
      [name]: date
    })
  }

  _editProfileClick() {
    this.setState({
      editProfile: true
    })
  }

  _handleNavSelect(eventKey) {
    if (eventKey === "preview") {
      this.setState({
        editProfile: false,
        editSchool: false,
        editExperience: false,
        editProject: false,
        editSkill: false,
      })
    }
    this.setState({
      stage: eventKey
    })
    // this._handleSubmit()
  };

__getValidationRule(type) {
    let rule = [];
    switch (type) {
      case 'school':
        rule = [
          { field: "name", rules: ["required", "string"] },
          { field: "course", rules: ["required", "string"] },
          { field: "degree", rules: ["required", "string"] },
          { field: "start_year", rules: ["required", "date"] },
          { field: "end_year", rules: ["required", "date", "greater|start_year"] },
          { field: "address", rules: ["required", "string"] },
        ]
        break;
      case 'experience':
        rule = [
          { field: "company", rules: ["required", "string"] },
          { field: "company_address", rules: ["required", "string"] },
          { field: "position", rules: ["required", "string"] },
          { field: "role", rules: ["string"] },
          { field: "description", rules: ["required", "string"] },
          { field: "start_date", rules: ["required", "date"] },
          { field: "end_date", rules: ["required", "date", "greater|start_date"] },
        ]
        break;
        case 'project':
          rule = [
            { field: "name", rules: ["required", "string"] },
            { field: "year", rules: ["required", "date"] },
            { field: "description", rules: ["required", "string"] },
          ]
        break;
        case 'cv':
        rule = [
          { field: "firstname", rules: ["required", "string"] },
          { field: "lastname", rules: ["required", "string"] },
          { field: "phone", rules: ["required", "string"] },
          { field: "address", rules: ["string"] },
          { field: "country", rules: ["string"] },
          { field: "city", rules: ["string"] },
          { field: "gender", rules: ["string"] },
          { field: "date_of_birth", rules: ["date"] },
          { field: "about", rules: [] },
          { field: "website", rules: [] },
          { field: "linkedIn", rules: [] },
          { field: "github", rules: [] },
          { field: "educations", rules: ["array"] },
          { field: "organizations", rules: ["array"] },
          { field: "skills", rules: ["array"] },
          { field: "projects", rules: ["array"] },
        ]
        break;
      case 'skill':
        rule = [
          { field: "name", rules: ["required", "string"] },
        ]
        break;
      default:
        break;
    }

    return rule;
  }

  _handleUpload(file) {
    this.setState({
      uploadingFile: true
    })
    const formData = new FormData();
    formData.append('image', file)
    UploadAvatarFile(formData).then(res => {
      console.dir(res)
    }).catch(err => {
      console.dir(err)
    }).finally(() => {
      // runs after upload 
      this.setState({
        uploadingFile: false
      })
    })
  }

  _handleSubmit() {

    let personalDetails = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      country: this.state.country,
      city: this.state.city,
      gender: this.state.gender,
      date_of_birth: this.state.date_of_birth,
      about: this.state.about,
      website: this.state.website,
      linkedIn: this.state.linkedIn,
      github: this.state.github,
      educations: this.state.schools,
      organizations: this.state.experiences,
      skills: this.state.skills,
      projects: this.state.projects
    }

    const __validationRules = this.__getValidationRule('cv');

    let validator = new Validator(personalDetails, __validationRules);
    validator.validate();
    console.log(personalDetails, validator.errors);
    if (!validator.isValid) {
      console.log("error")
      this.setState({
        error: validator.errors
      });
      return false;
    }

    this.setState({
      loading: true
    });

    CreateCV(personalDetails).then(res => {
      console.log(res)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.setState({
        loading: false
      });
    })
  }

  _editComponent(i, type) {
    let component = this.state[type + 's'][i];
    let FLCtype = type.charAt(0).toUpperCase() + type.slice(1);
    // this._deleteComponent(i, type);
    this.setState({
      [type]: component,
      ["edit" + FLCtype]: true
    })
  }

  _deleteComponent(i, type) {
    let r = this.state[type + 's'].filter((component, idx) => idx !== i)
    let elem = this.state[type + 's'].find((component, idx) => idx === i)
    this.setState({
      [type + 's']: [
        ...r,
      ],
    })
    type = type === "school" ? "education" : type;
    DeleteComponent(type, elem.id).then(res => {
      toast.info('deleted!');
    })
  }

  _addComponent(type) {
    let FLCtype = type.charAt(0).toUpperCase() + type.slice(1);

    if (!this.state["edit" + FLCtype]) {
      this.setState({
        ["edit" + FLCtype]: true
      });
      return false
    }
    this.setState({
      errors: {
        ...this.state.errors,
        ...{ [type]: {} }
      }
    });    

    const __validationRules = this.__getValidationRule(type);
    let data = this.state[type];
    let validator = new Validator(data, __validationRules);
    validator.validate();

    if (!validator.isValid) {
      this.setState({
        errors: {
          ...this.state.errors,
          ...{ [type]: validator.errors }
        }
      });
      return false;
    }
    

    this.setState({
      [type + 's']: [
        ...this.state[type + 's'],
        data
      ],
    }, () => {
      this._hideForm(type)
    });

  }

  _nullObject = (obj, val) => {
    let newObj = {}
    Object.keys(obj).forEach(k => {
      newObj[k] = val
    })
    return newObj
  }

  _hideForm(type) {
    const newType = this._nullObject(this.state[type], '')
    let FLCtype = type.charAt(0).toUpperCase() + type.slice(1);
    this.setState({
      [type]: newType,
      errors: {
        ...this.state.errors,
        ...{ [type]: {} }
      }
    }, () => {
      if (!this.leaveOpen.includes(type)) {
        this.setState({
          ["edit" + FLCtype]: false
        })
      }

    });
  }

  _applyClick() {
    let personalDetails = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      country: this.state.country,
      city: this.state.city,
      gender: this.state.gender,
      date_of_birth: this.state.date_of_birth,
      about: this.state.about,
      website: this.state.website,
      linkedIn: this.state.linkedIn,
      github: this.state.github,
      educations: this.state.schools,
      organizations: this.state.experiences,
      skills: this.state.skills,
      projects: this.state.projects
    }

    const __validationRules = this.__getValidationRule('cv');

    let validator = new Validator(personalDetails, __validationRules);
    validator.validate();
    console.log(personalDetails, validator.errors);
    if (!validator.isValid) {
      this.setState({
        error: validator.errors
      });
      return false;
    }
    
    // console.log("good")
    let jobId = this.props.job.id;
    if (!jobId) {
      return false;
    }
    this.setState({
      loadingApply: true
    })
    // console.log("good")
    Apply({ jobId }).then(res => {
      const { auto_assess } = res.data.data
      this.setState({
        showModal: true,
        modal:{
          heading: "",
          image: Checkmark,
          body: "Application successful.",
          footer: ""
        }
      }, () => {
        let location = 'applications'
        if (!!auto_assess || !!this.props.job.auto_access) {
          location = 'question'
          this.setState({
            modal: {
              ...this.state.modal,
              body: "please breifly take your time to answer this few questions"
            }
          })
        }
        setTimeout(() => {
          this.props.history.push(location)
        }, 5000);
      })

    }).catch(err => {
      this.setState({
        showModal: true,
        modal:{
          heading: "unsuccessful",
          image: unsuccessful,
          body: "please retry.",
          footer: ""
        }
      })
      setTimeout(() => {
        this.setState({showModal: false})
      }, 5000);
      console.log(err)
    }).finally(() => {
      this.setState({
        loadingApply: false
      })
    })
  }

  _handleImageUpload(e) {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = this.uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      this._handleUpload(file)
    }
  };

  _handleResumeUpload = (e) => {
    const self = this;
    const file = e.target.files[0]
    console.log(file)
    const formData = new FormData();
    formData.append('cv', file)
    this.setState({
      uploadingCV: true
    })
      UploadCVFile(formData).then(res => {
                self.setState({
                  cv: res.data.data.cv
                });
                // this.showAccess()
              }).catch(err => {
                console.dir(err)
              }).finally(() => {
                // runs after upload 
                this.setState({
                  uploadingCV: false
                })
              // })
            }).catch((reject) => {
              console.error(reject)
            });
        this.setState({
          uploadingCV: false
        })

    // fileReader.readAsArrayBuffer(file);
  };



  render() {
    return (
      <Page>
        {
          this.state.showModal ?
            <Modal heading={this.state.modal.heading} image={this.state.modal.image} body={this.state.modal.body} footer={this.state.modal.footer} />
            : ""
        }
        <Container fluid>
          <Row>
            <Col>
              <div className="profile-img-section">
                <input type="file" accept="image/*" onChange={this._handleImageUpload} ref={this.imageUploader} />
                <div className="profile-div">
                  <img className="profile-img-center w-100 h-100 image-border" src={this.state.image || avatarIcon} alt="avatar-icon" ref={this.uploadedImage} />
                </div>
                <div className="profile-div" onClick={() => this.imageUploader.current.click()}>
                  {
                    this.state.uploadingFile ?
                      <Spinner animation="grow" /> : ''
                  }
                  <img className="camera-icon-center w-100 h-100 image-border" src={cameraIcon} alt="camera-icon" />
                </div>
              </div>
            </Col>
          </Row>
          <br /><br />
          <Row>
            <Col xs={12} md={8}>
              {
                window.location.pathname.split('/')[1] !== "profile" ?
                  <p className="" > <a className="head-link" href="/career">Open Positions</a>  &gt; &nbsp;&nbsp; {this.props.job.title || "Unknown"}   &gt; &nbsp;&nbsp; <strong> Apply Now</strong></p>
                  :
                  <p className="" >Personal Details  &gt; &nbsp;&nbsp; Profile   &gt; &nbsp;&nbsp; <strong> {this.state.stage}</strong></p>
              }
            </Col>
            <Col xs={12} md={4}>
              <button className="linkedIn-button">
                <img src={LinkedIn} alt="in" width="15px" /> &nbsp;  &nbsp;
                                APPLY WITH LINKEDIN
                            </button>
            </Col>
          </Row>
          <br />
          <div className="big-box">
            <Row>
              <Col xs={12} className="nav-col" >
                <Nav justify variant="tabs" defaultActiveKey={this.state.stage} activeKey={this.state.stage} onSelect={this._handleNavSelect}>
                  <Nav.Item>
                    <Nav.Link eventKey="profile">BASICS</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="education">EDUCATION</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="resume">RESUME</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="experience">EXPERIENCE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="preview">PREVIEW</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <br /><br />






            <div className={` ${(this.state.stage === 'profile' || this.state.stage === 'preview') ? '' : ' hide'}`}>
              <Row >
                <Col xs={12} md={5}>
                  <p className="personal-box" >Personal information</p>
                  <span className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>Kindly complete your profile to continue</span>
                </Col>
                <Col xs={12} md={7}>
                  <div className={`preview ${this.state.editProfile ? ' hide' : ''}`}>
                    <button className={`edit-box absolute-right ${this.state.stage === 'preview' ? ' hide' : ''}`} onClick={this._editProfileClick}>Edit</button>
                    <p><strong>Full name</strong></p>
                    <span>
                      {this.state.firstname} {this.state.lastname}
                      {(!this.state.firstname && !this.state.lastname) ? 'not available' : ''}
                    </span>
                    <p><strong>Email</strong></p>
                    <span>{this.state.email || "your@gmail.com"}</span>
                    <p><strong>Phone Number</strong></p>
                    <span>{this.state.phone || 'not available'}</span>
                    <p><strong>Address</strong></p>
                    <span>
                      {this.state.city} {this.state.country ? ', ' + this.state.country : ''}
                      {(!this.state.city && !this.state.country) ? 'not available' : ''}
                    </span>
                  </div>
                  <div className={` ${!this.state.editProfile ? ' hide' : ''}`}>

                    <Input placeholder="firstname" label={"First Name <font color='red'>*<font/>"} name="firstname" value={this.state.firstname} onChange={this._handleChange} error={this.state.error.firstname} />
                    <Input placeholder="lastname" label="Last Name <font color='red'>*<font/>" name="lastname" value={this.state.lastname} onChange={this._handleChange} error={this.state.error.lastname} />
                    {/* <Input placeholder="your@email.com" label="Email <font color='red'>*<font/>" name="email" value={this.state.email} onChange={this._handleChange} error={this.state.error.email} disabled={true}/> */}
                    <Input placeholder="08123456789" label="Phone Number <font color='red'>*<font/>" name="phone" value={this.state.phone} onChange={this._handleChange} error={this.state.error.phone} />
                    <Text placeholder="Your home Address" label="Address" name="address" value={this.state.address} onChange={this._handleChange} error={this.state.error.address} />
                    <Row className="row-m-i">
                      <Col xs={12} md={6}>
                        <Select
                          label="Country"
                          options={[{ value: "Nigeria" }, { value: "Ghana" }, { value: "Benin" }]}
                          name="country"
                          value={this.state.country}
                          onChange={this._handleChange}
                          error={this.state.error.country}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input placeholder="city" label="City" name="city" value={this.state.city} onChange={this._handleChange} error={this.state.error.city} />
                      </Col>
                    </Row>
                    <Row className="row-m-i">
                      <Col xs={12} md={6}>
                        <Select
                          label="Gender"
                          options={[{ value: "male" }, { value: "female" }]}
                          name="gender"
                          value={this.state.gender}
                          onChange={this._handleChange}
                          error={this.state.error.gender}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <DateTime label="Date of Birth" name="date_of_birth" icon={Calender} value={this.state.date_of_birth} onChange={(e) => this._handleChangeDate(e, 'date_of_birth')} error={this.state.error.date_of_birth} dateFormat="DD-MM-YYYY" />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <hr className={`hr ${this.state.editProfile ? '' : ' hide'}`} />
              <Row className={` ${this.state.editProfile ? '' : ' hide'}`}>
                <Col xs={12} md={5}>
                  <p className="personal-box" >About</p>
                  <span className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>Tell us more about you</span>
                </Col>
                <Col xs={12} md={7}>
                  <Text placeholder="briefly write something cool about yourself..." label="About" name="about" value={this.state.about} onChange={this._handleChange} error={this.state.error.about} />
                  <Input placeholder="http://yoursite.com" label={"Website"} name="website" value={this.state.website} onChange={this._handleChange} error={this.state.error.website} />
                  <Input placeholder="https://linkedIn.com/linkedin-ID" label={"LinkedIn"} name="linkedIn" value={this.state.linkedIn} onChange={this._handleChange} error={this.state.error.linkedIn} />
                  <Input placeholder="https://github.com/username" label={"GitHub"} name="github" value={this.state.github} onChange={this._handleChange} error={this.state.error.github} />
                </Col>
              </Row>

              <div className={`walk-through ${this.state.stage === 'preview' ? 'hide' : ''}`}>
                <button className="edit-box next" onClick={() => this._handleNavSelect('education')}> Next  &gt; </button>
              </div>
            </div>
            <hr className={`hr ${this.state.stage === 'preview' ? '' : ' hide'}`} />







            <Row className={` ${(this.state.stage === 'education' || this.state.stage === 'preview') ? '' : ' hide'}`}>
              <Col xs={12} md={5}>
                <p className="personal-box" >Education</p>
                <span className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>Please provide your education history</span>
              </Col>
              <Col xs={12} md={7}>
                <div className={`preview`}>
                  {
                    this.state.schools.length === 0 ?
                      <div>
                        <span>
                          No school history
                                            </span>
                        <hr className={` ${this.state.stage === 'preview' ? ' hide' : ''}`} />
                      </div>
                      :
                      this.state.schools.map((school, i) => {
                        return (
                          <div key={i} className={`listing`}>
                            <div className={`option ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                              <span className="edit" onClick={() => this._editComponent(i, 'school')} >
                                <img src={Pen} alt="/" width="15px" />
                              </span>
                              <span className="delete" onClick={() => this._deleteComponent(i, 'school')} >
                                <img src={Delete} alt="x" width="15px" />
                              </span>
                            </div>

                            <p><strong>School</strong></p>
                            <span>{school.name}</span>
                            <br />
                            <Row className="row-m-i">
                              <Col xs={12} md={6}>
                                <p><strong>Course</strong></p>
                                <span>{school.course}, &nbsp;
                                  {school.end_year ? school.end_year.split('-')[2] : 'till date'}
                                </span>
                              </Col>
                              <Col xs={12} md={6}>
                                <p><strong>Degree</strong></p>
                                <span>{school.degree}</span>
                              </Col>
                            </Row>
                            <hr />
                          </div>
                        )
                      })

                  }

                </div>
                <div className={` ${!this.state.editSchool ? ' hide' : ''}`}>
                  <Input placeholder="School" label="School" name="name" value={this.state.school.name} onChange={(e) => this._handleOnChange(e, 'school')} error={this.state.errors.school.name} />
                  <Row className="row-m-i">
                    <Col xs={12} md={7}>
                      <Input placeholder="Course" label="Course" name="course" value={this.state.school.course} onChange={(e) => this._handleOnChange(e, 'school')} error={this.state.errors.school.course} />
                    </Col>
                    <Col xs={12} md={5}>
                      <Select label="Degree"
                        name="degree"
                        value={this.state.school.degree} options={[
                          { value: "Bsc", label: "Bachelor of Sci." },
                          { value: "BTech", label: "Bachelor of Tech." },
                          { value: "Msc", label: "Masters" },
                          { value: "Phd", label: "Phd" },
                          { value: "Hnd", label: "Higher National Dip." },
                          { value: "Ond", label: "Ordinary National Dip." },
                          { value: "Ssce", label: "Senior Secondary Certificate of Edu." },
                          { value: "MEng", label: "Master of Engineering" },
                          { value: "MTech", label: "Master of Technology" },
                          { value: "Nce", label: "Nigeria Certificate in Education" }
                        ]}
                        onChange={(e) => this._handleOnChange(e, 'school')}
                        error={this.state.errors.school.degree}
                      />
                    </Col>
                  </Row>
                  <Row className="row-m-i">
                    <Col xs={12} md={6}>
                      <DateTime label="Start Date" name="start_year" icon={Calender} value={this.state.school.start_year} onChange={(e) => this._handleOnChangeDate(e, 'start_year', 'school')} error={this.state.errors.school.start_year} />
                    </Col>
                    <Col xs={12} md={6} className={`${(this.state.school.show_end_year === "off" || !this.state.school.show_end_year) ? '' : 'hide'}`} >
                      <DateTime label="End Date" name="end_year" icon={Calender} value={(this.state.school.show_end_year === "off" || !this.state.school.show_end_year) ? '' : this.state.school.end_year} onChange={(e) => this._handleOnChangeDate(e, 'end_year', 'school')} error={this.state.errors.school.end_year} />
                    </Col>
                    <Col xs={12} md={6} className={`${(this.state.school.show_end_year === "off" || !this.state.school.show_end_year) ? 'hide' : ''}`} >
                      <span className="centralize">Till date</span>
                    </Col>
                  </Row>

                  <Form.Check
                    type="switch"
                    id="school-custom-switch"
                    label="I currently school here."
                    value={(!this.state.school.show_end_year || this.state.school.show_end_year === "off") ? "on" : "off"}
                    name="show_end_year"
                    onChange={(e) => this._handleOnChange(e, 'school')}
                  />
                  <br />
                  <Text placeholder="Address" label="Address" name="address" value={this.state.school.address} onChange={(e) => this._handleOnChange(e, 'school')} error={this.state.errors.school.address} />


                  <br />
                  <br />
                </div>
                <div className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                  <button className="edit-box" onClick={() => this._addComponent('school')}>  {this.state.editSchool ? ' Save ' : 'Add New'}</button>  &nbsp; <a href="#!" className={` ${!this.state.editSchool ? ' hide' : ''}`} onClick={() => this._hideForm('school')}>cancel</a>
                </div>
              </Col>
              <br />
              <div className={`walk-through ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                <button className="edit-box back" onClick={() => this._handleNavSelect('profile')}>  &lt; Back </button>
                <button className="edit-box next" onClick={() => this._handleNavSelect('resume')}> Next  &gt; </button>
              </div>
            </Row>
            <hr className={`hr ${this.state.stage === 'preview' ? '' : ' hide'}`} />



            <Row className={`pb-4 ${(this.state.stage === 'resume' || this.state.stage === 'preview') ? '' : ' hide'}`}>
              <Col xs={12} md={5}>
                <p className="personal-box" >Resume/CV</p>
                <span className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}> Kindly attach your resume/CV file</span>
              </Col>
              <Col xs={12} md={7}>

                {/* ADDED AN ONCLICK HANDLER TO THIS DIV */}
                <div className={`p-2 ${this.state.cv ? '' : 'hide'}`} >
                  <iframe ref={this.uploadedResume} title="resume" src={this.state.cv} width="100%" height="600" />
                  {/* <object type="application/pdf" data={this.state.cv} width="250" height="200" >browser not supported</object> */}
                </div>
                <div className="p-2">
                  <span onClick={() => this.resumeUploader.current.click()} className={`input-file ${this.state.stage === 'preview' ? 'hide' : ''}`} > Upload CV
                                        <input
                      type="file"
                      // accept = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                      accept="application/pdf"
                      onChange={this._handleResumeUpload}
                      ref={this.resumeUploader}

                    />

                    {
                      this.state.uploadingCV ?
                        <Spinner animation="grow" /> : ''
                    }
                  </span>
                </div>

              </Col>
              <br />
              <div className={`walk-through ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                <button className="edit-box back" onClick={() => this._handleNavSelect('education')}>  &lt; Back </button>
                <button className="edit-box next" onClick={() => this._handleNavSelect('experience')}> Next  &gt; </button>
              </div>
            </Row>
            <hr className={`hr ${this.state.stage === 'preview' ? '' : ' hide'}`} />



            <Row className={` ${(this.state.stage === 'experience' || this.state.stage === 'preview') ? '' : ' hide'}`}>
              <Col xs={12} md={5}>
                <p className="personal-box" >Work Experience</p>
                <span className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>Please add any relevant work experience</span>
              </Col>
              <Col xs={12} md={7}>
                <div className={`preview`}>
                  {
                    this.state.experiences.length === 0 ?
                      <div>
                        <span>
                          No work history
                                            </span>
                        <hr className={` ${this.state.stage === 'preview' ? ' hide' : ''}`} />
                      </div>
                      :
                      this.state.experiences.map((experience, i) => {
                        return (
                          <div className={`listing`} key={i}>
                            <div className={`option ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                              <span className="edit" onClick={() => this._editComponent(i, 'experience')} >
                                <img src={Pen} alt="/" width="15px" />
                              </span>
                              <span className="delete" onClick={() => this._deleteComponent(i, 'experience')} >
                                <img src={Delete} alt="x" width="15px" />
                              </span>
                            </div>
                            <p>
                              <strong>
                                {experience.position} &nbsp;
                                                        </strong>
                                                        @ &nbsp; {experience.company} &nbsp;
                                                        ({experience.company_address}), &nbsp;
                                                        {moment(experience.start_date).format("MMM DD")} - &nbsp;
                                                        {experience.end_date ? moment(experience.end_date).format("MMM DD") : 'Present'}

                            </p>
                            <span>
                              {
                               experience.description ? experience.description : experience.description.split('-').map((desc, i) => desc !== '' ? <p key={i}> - {desc} </p> : '')
                              }
                            </span>
                            <hr />
                          </div>
                        )
                      })

                  }
                </div>
                <div className={` ${!this.state.editExperience ? ' hide' : ''}`}>
                  <Input placeholder="Employer" label="Employer" name="company" value={this.state.experience.company} onChange={(e) => this._handleOnChange(e, 'experience')} error={this.state.errors.experience.company} />
                  <Text placeholder="Address" label="Address" name="company_address" value={this.state.experience.company_address} onChange={(e) => this._handleOnChange(e, 'experience')} error={this.state.errors.experience.company_address} />
                  <Input placeholder="Position" label="Position" name="position" value={this.state.experience.position} onChange={(e) => this._handleOnChange(e, 'experience')} error={this.state.errors.experience.position} />
                  <Input placeholder="Role" label="Role" name="role" value={this.state.experience.role} onChange={(e) => this._handleOnChange(e, 'experience')} error={this.state.errors.experience.role} />
                  <Text placeholder="Description" label="Description" name="description" value={this.state.experience.description} onChange={(e) => this._handleOnChange(e, 'experience')} error={this.state.errors.experience.description} />
                  <Row className="row-m-i">
                    <Col xs={12} md={6}>
                      <DateTime label="Start Year" icon={Calender} value={this.state.experience.start_date} onChange={(e) => this._handleOnChangeDate(e, 'start_date', 'experience')} error={this.state.errors.experience.start_date} />
                    </Col>
                    <Col xs={12} md={6} className={` ${(this.state.experience.show_end_date === "off" || !this.state.experience.show_end_date) ? '' : 'hide'}`}>
                      <DateTime label="End Year" icon={Calender} value={(this.state.experience.show_end_date === "off" || !this.state.experience.end_date) ? '' : this.state.experience.end_date} onChange={(e) => this._handleOnChangeDate(e, 'end_date', 'experience')} error={this.state.errors.experience.end_date} />
                    </Col>
                    <Col xs={12} md={6} className={`${(this.state.experience.show_end_date === "off" || !this.state.experience.show_end_date) ? 'hide' : ''}`} >
                      <span className="centralize">Till date</span>
                    </Col>
                  </Row>
                  <Form.Check
                    type="switch"
                    id="experience-custom-switch"
                    label="I currently work here."
                    value={(!this.state.experience.show_end_date || this.state.experience.show_end_date === "off") ? "on" : "off"}
                    name="show_end_date"
                    onChange={(e) => this._handleOnChange(e, 'experience')}
                  />
                  <br />
                  <br />
                </div>

                <div className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                  <button className="edit-box" onClick={() => this._addComponent('experience')}> Add {+ this.state.editExperience ? ' More' : ' New'}</button>   &nbsp; <a href="#!" className={` ${!this.state.editExperience ? ' hide' : ''}`} onClick={() => this._hideForm('experience')}>cancel</a>
                </div>
              </Col>
              <br />
            </Row>
            <hr className={`hr ${(this.state.stage === 'experience' || this.state.stage === 'preview') ? '' : ' hide'}`} />







            <Row className={` ${(this.state.stage === 'experience' || this.state.stage === 'preview') ? '' : ' hide'}`}>
              <Col xs={12} md={5}>
                <p className="personal-box" >Projects</p>
                <span className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>Please tell us about the projects you have worked on</span>
              </Col>
              <Col xs={12} md={7}>
                <div className={`preview`}>
                  {
                    this.state.projects.length === 0 ?
                      <div>
                        <span>
                          No work project
                                            </span>
                        <hr className={` ${this.state.stage === 'preview' ? ' hide' : ''}`} />
                      </div>
                      :
                      this.state.projects.map((project, i) => {
                        return (
                          <div className={`listing`} key={i}>
                            <div className={`option ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                              <span className="edit" onClick={() => this._editComponent(i, 'project')} >
                                <img src={Pen} alt="/" width="15px" />
                              </span>
                              <span className="delete" onClick={() => this._deleteComponent(i, 'project')} >
                                <img src={Delete} alt="x" width="15px" />
                              </span>
                            </div>
                            <p><strong>{project.name} - {moment(project.year).format("YYYY")}</strong></p>
                            <span>
                              {
                                project.description ? project.description : project.description.split('-').map((desc, i) => desc !== '' ? <p key={i}> - {desc} </p> : '')
                              }
                            </span>
                            <hr />
                          </div>
                        )
                      })

                  }
                </div>
                <div className={` ${!this.state.editProject ? ' hide' : ''}`}>
                  <Input placeholder="Name" label="Name" name="name" value={this.state.project.name} onChange={(e) => this._handleOnChange(e, 'project')} error={this.state.errors.project.name} />
                  <Text placeholder="Description" label="Description" name="description" value={this.state.project.description} onChange={(e) => this._handleOnChange(e, 'project')} error={this.state.errors.project.description} />
                  <Row className="row-m-i">
                    <Col xs={12} md={6}>
                      <DateTime label="Year" icon={Calender} value={this.state.project.year} onChange={(e) => this._handleOnChangeDate(e, 'year', 'project')} error={this.state.errors.project.year} dateFormat="YYYY" />
                    </Col>
                  </Row>
                  <br />
                </div>
                <div className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                  <button className="edit-box" onClick={() => this._addComponent('project')}> Add {+ this.state.editProject ? ' More' : ' New'}</button>  &nbsp; <a href="#!" className={` ${!this.state.editProject ? ' hide' : ''}`} onClick={() => this._hideForm('project')}>cancel</a>
                </div>
              </Col>
              <br />
            </Row>
            <hr className={`hr ${(this.state.stage === 'experience' || this.state.stage === 'preview') ? '' : ' hide'}`} />

            <Row className={` ${(this.state.stage === 'experience' || this.state.stage === 'preview') ? '' : ' hide'}`}>
              <Col xs={12} md={5}>
                <p className="personal-box" >Skills</p>
                <span className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>Please add your skills</span>
              </Col>
              <Col xs={12} md={7}>
                <div className={`preview`}>
                  {
                    this.state.skills.length === 0 ?
                      <div>
                        <span>
                          No work skill
                                            </span>
                        <hr className={` ${this.state.stage === 'preview' ? ' hide' : ''}`} />
                      </div>
                      :
                      // Object.values(this.state.skills).map((skill, i) => {
                      //     return (
                      //         <div className={`listing`} key={i}>
                      //             <div className={`option ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                      //                 <span className="edit" onClick={() => this._editComponent(i, 'skill')} >
                      //                     <img src={Pen} alt="/" width="15px" />
                      //                 </span>
                      //                 <span className="delete" onClick={() => this._deleteComponent(i, 'skill')} >
                      //                     <img src={Delete} alt="x" width="15px" />
                      //                 </span>
                      //             </div>
                      //             <span>
                      //                 - {skill}
                      //             </span>
                      //         </div>
                      //     )
                      // })
                      this.state.skills.map((skill, i
                      ) => {
                        return (
                          <div className={`listing`} key={i}>
                            <div className={`option ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                              <span className="edit" onClick={() => this._editComponent(i, 'skill')} >
                                <img src={Pen} alt="/" width="15px" />
                              </span>
                              <span className="delete" onClick={() => this._deleteComponent(i, 'skill')} >
                                <img src={Delete} alt="x" width="15px" />
                              </span>
                            </div>
                            <span>
                              {skill.name}
                            </span>
                          </div>
                        )
                      })

                  }
                </div>
                <div className={` ${!this.state.editSkill ? 'hide' : ''}`}>
                    <Input placeholder="Skill"  label="Skill" name="name" value={this.state.skill.name} onChange={(e) => this._handleOnChange(e, 'skill')}  error={this.state.errors.skill.name} />
                </div>
                <div className={` ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                  {/* <button className="edit-box" onClick={() => this._addComponent('skill')}> Add {+ this.state.editSkill ? ' More' : ' New'}</button> &nbsp; <a href="#!" className={` ${!this.state.editSkill ? 'hide' : ' '}`} onClick={() => this._hideForm('skill')}>cancel</a> */}
                  <button className="edit-box" onClick={() => this._addComponent('skill')}> Add {+ this.state.editSkill ? ' More' : ' New'}</button>  &nbsp; <a href="#!" className={` ${!this.state.editSkill ? ' hide' : ''}`} onClick={() => this._hideForm('skill')}>cancel</a>
                </div>                                             
              </Col>
              <br />

              <div className={`walk-through ${this.state.stage === 'preview' ? ' hide' : ''}`}>
                <button className="edit-box back" onClick={() => this._handleNavSelect('resume')}>  &lt; Back </button>
                <button className="edit-box next" onClick={() => this._handleNavSelect('preview')}> Next &gt; </button>
              </div>

            </Row>
            <hr className={`hr ${this.state.stage === 'preview' ? '' : ' hide'}`} />


            <Row className={` ${this.state.stage === 'preview' ? '' : ' hide'}`} >

              {
                window.location.pathname.split('/')[1] === "profile" ?

                  <Col xs={{ span: 6, offset: 6 }} md={{ span: 4, offset: 8 }}>
                    {
                      Object.values(this.state.error).map((err, i) => {
                        return (
                          <p key={i}><font color="red"> - {err.join(', ')}</font></p>
                        )
                      })
                    }
                    <Button loading={this.state.loading} text="save" onClick={this._handleSubmit} />
                  </Col>
                  :
                  <Col xs={{ span: 12 }}> {
                    Object.values(this.state.error).map((err, i) => {
                      return (
                        <p key={i}><font color="red"> - {err.join(', ')}</font></p>
                      )
                    })
                  }
                    <div className="apply-section">
                      <Button loading={this.state.loadingApply} text="CONFIRM & APPLY" onClick={this._applyClick} />
                      <p className="application-note text-center mt-4"> NOTE: Applications once submitted cannot be accessed </p>
                    </div>
                  </Col>
              }
            </Row>
            <hr />

          </div>
        </Container>

      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    job: state.job.job
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatchJob: (res) => {
    //     dispatch({type: 'SELECT_JOB', job: res})
    // }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(personaldetails);