import State from './state'
// import { parseJwt } from '../actions/user'
import { nextStage } from '../actions/job'
import jwt_decode from "jwt-decode";

const __state = {
    ...State, 
    user: {password:'', email:''},
    admin: {},
    isAuthenticated: true,
    isAdminAuthenticated: false,
    isVerified: false,
    isAdminVerified: false,
    hasAccount: false,
    hidePass: true
}

const user = (state = __state, action) => {
    // state.loading
    const { type } = action

    switch(type) {
        case 'USER_REGISTERED':
            const { res: {access_token, email} } = action;
            localStorage.setItem("rt", JSON.stringify(access_token));
            setTimeout(() => {
                nextStage()
            }, 1000)
            return {
                ...state,
                isAuthenticated: true,
                user: {...state.user, email: email}
            }
        case 'USER_LOGIN':
            const { res } = action;
            let token = res.access_token;
            let exp = res.expected;
            localStorage.setItem("rt", JSON.stringify(token));
            localStorage.setItem("rtExpt", JSON.stringify(exp));
            //let _user = parseJwt(token);
            setTimeout(() => {
                nextStage()
            }, 2000)
            return {
                ...state,
                isAuthenticated: true,
                isVerified: !!exp, //_user.verified,
            }
        case 'USER_VERIFIED':
            setTimeout(() => {
                nextStage()
            }, 2000)
            let _exp = action.res.expected || (new Date()).toISOString();
            localStorage.setItem("rtExpt", JSON.stringify(_exp))
            return {
                ...state,
                isAuthenticated: true,
                isVerified: !!_exp, //_user.verified,
            }
        case 'ADMIN_REGISTERED':
            const { adminRes: {access_token_admin, expected} } = action;
            localStorage.setItem("rta", JSON.stringify(access_token_admin));
            setTimeout(() => {
                window.location.href = '/admin-verification'
            }, 1000)
            return {
                ...state,
                isAdminAuthenticated: true,
                isAdminVerified: expected
            }
        case 'ADMIN_LOGIN':
            const _res = action.res;
            let adminToken = _res.access_token;
            let adminExp = _res.expected;
            const {sub} = jwt_decode(adminToken)
            localStorage.setItem(sub, JSON.stringify(sub));
            console.log(sub)
            localStorage.setItem("rta", JSON.stringify(adminToken));
            localStorage.setItem("rtaExpt", JSON.stringify(adminExp));
            localStorage.removeItem("rt")
            localStorage.removeItem("rtExpt")
            //let _user = parseJwt(token);
            setTimeout(() => {
                window.location.href = "/admin-dashboard"
            }, 1000)
            return {
                ...state,
                isAdminAuthenticated: true,
                isAdminVerified: !!adminExp, //_user.verified,
            }
        case 'USER_LOGOUT':
            localStorage.removeItem("rt")
            localStorage.removeItem("rtExpt")
            return {
                ...state,
                isAuthenticated: false,
                isVerified: false
            }
        case 'ADMIN_LOGOUT':
            localStorage.removeItem("rta")
            localStorage.removeItem("rtaExpt")
            localStorage.removeItem("apr")
            return {
                ...state,
                isAuthenticated: false,
                isVerified: false
            }
        case 'USER_TOGGLE_PASSWORD':
            return {
                ...state,
                hidePass: !state.hidePass
            }
        case 'USER_INPUT_CHANGE':
            return {
                ...state,
                user:{ ...state.user, [action.field]: action.value }
            }
        default:
            let _token = JSON.parse(localStorage.getItem('rt'));
            let _admin_token = JSON.parse(localStorage.getItem('rta'));
            let rtExpt = JSON.parse(localStorage.getItem('rtExpt'));
            let rtaExpt = JSON.parse(localStorage.getItem('rtaExpt'));
            return {
                ...state,
                isAuthenticated: !!_token,
                isAdminAuthenticated: !!_admin_token,
                isVerified: !!rtExpt,
                isAdminVerified: !!rtaExpt
            }
    }
    
}

export default user