import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import LogoSm from '../../asset/images/e-settlement-logo-sm.png';
import '../../asset/css/nav.css';
import { ButtonGroup, Button, Row, Fade } from 'react-bootstrap';
import { connect } from 'react-redux'

class AppNav extends Component {

    constructor(props) {
        super(props);
        this.navigate = this.navigate.bind(this);
        this.state = {
            path: window.location.pathname === '/' ? window.location.hash === "" ? 'product' : window.location.hash.slice(1) : window.location.pathname.slice(1),
            pathname: window.location.pathname
        }
    }

    navigate(link) {
        window.location.href = link
    }

    render(){
        return (
            <div className="navigation">
                <Navbar expand="lg" sticky="top">
                    <Navbar.Brand href="#home">
                        <a href="/"><img src={ LogoSm } alt="E-Settlement Logo"/></a>
                    </Navbar.Brand>

                    <Navbar.Toggle>
                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-3.05176e-05 0H24V3H-3.05176e-05V0ZM5.99997 7.5H24V10.5H5.99997V7.5ZM13.5 15H24V18H13.5V15Z" fill="#022295"/>
                        </svg>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto" defaultActiveKey={this.state.path} onSelect={this._handleNavSelect}>
                            <Nav.Link className="header-nav-link" eventKey="product" href="/#product">Our Products</Nav.Link>
                            <Nav.Link className="header-nav-link" eventKey="career" href="/career">Careers</Nav.Link>
                            <Nav.Link className="header-nav-link" eventKey="contact" href="/contact">Contact</Nav.Link>
                            <Nav.Link className="header-nav-link" eventKey="blog" href="https://blog.esettlementgroup.com" target="_blank">Blog</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Fade in={this.props.isAuthenticated && this.state.pathname !== "/"}>
                    { 
                        this.state.pathname !== "/" ?
                        <Row className="pt-4 px-4 auth-nav justify-content-end" >
                            <ButtonGroup size="sm">
                                <Button onClick={() => this.navigate('profile')}>profile</Button>
                                <Button onClick={() => this.navigate('applications')}>applications</Button>
                                <Button variant="danger" onClick={ this.props.dispatchLogout }>logout</Button>
                            </ButtonGroup>
                        </Row>
                        : <div className=""></div>
                    }
                </Fade>
                
            </div>
                
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.user.isAuthenticated,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatchLogout: (res) => {
        dispatch({type: 'USER_LOGOUT', res: res})
      }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AppNav);
