import State from './state'

export const selJob = !!localStorage.getItem("job") ? JSON.parse(localStorage.getItem("job")) : {};

const __state = {
    ...State, 
    job: selJob,
}

const job = (state = __state, action) => {
    
    const { type } = action

    switch(type) {
        case 'SELECT_JOB':
            let job = action.job;
            localStorage.setItem("job", JSON.stringify(job))
            return {
                ...state,
                job: job
            }
        default:
            return {
                ...state,
            }
    }
    
}

export default job