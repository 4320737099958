import State from './state'

export const AllApplications = !!localStorage.getItem("applications") ? JSON.parse(localStorage.getItem("applications")) : {};

export const SelApplications = !!localStorage.getItem("application") ? JSON.parse(localStorage.getItem("application")) : {};

const __state = {
    ...State, 
    applications: AllApplications,
    application: SelApplications
}

const application = (state = __state, action) => {
    
    const { type, application, applications } = action

    switch(type) {
        case 'ALL_APPLICATION':
            localStorage.setItem("applications", JSON.stringify(applications))
            return {
                ...state,
                applications: applications
            }
        case 'SELECTED_APPLICATION':
            localStorage.setItem("application", JSON.stringify(application))
            return {
                ...state,
                application: application
            }
        default:
            return {
                ...state,
            }
    }
    
}

export default application