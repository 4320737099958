import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import AppNav from '../components/nav';
import Footer from '../components/footer.js';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../asset/css/App.css';


class Page extends Component {
  render() {
    return (
      <div>
        <AppNav/>
        <ToastContainer />
        <Container fluid={true} >
          <div className="contain">
            { this.props.children }
          </div>
        </Container>
        <Footer/>
      </div>
  );
}
}
   

export default Page;






















// class Page extends Component {

//   // constructor(props){
//   //   super( props )
//   // }

//   useStyles(){
//     return makeStyles(theme => ({
//       root: {
//         flexGrow: 1,
//       },
//       paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//       },
//     }));
//   }

//   render(){
    
//     const classes = this.useStyles();
//     console.log(classes);
//     return (
//       <div className={classes.root}>
//         <Container>
//         {/* { this.props.children } */}
//         </Container>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <Nav />
//             <Paper className={classes.paper}>
           
//             </Paper>
//           </Grid>
//           <Grid item xs={4}>
//             <Paper className={classes.paper}>
//             <LeftGrid />
//             </Paper>
//           </Grid>
//           <Grid item xs={8}>
//             <Paper className={classes.paper}>
//              { this.props.children }
//             </Paper>
//           </Grid>
//         </Grid>
//       </div>
//     );
//   }
  
// }

// export default Page;
