import * as moment from 'moment';

const REQUIRE = 'required';
const REQUIRE_MSG = ' is required'
const STRING = 'string';
const STRING_MSG = ' is not a string';
const EMAIL = 'email';
const EMAIL_MSG = ' is not a valid email format';
const NUMBER = 'number';
const NUMBER_MSG = ' is not a numeric value';
const DATE = 'date';
const DATE_MSG = ' is not a valid date';
const GREATER = 'greater';
const GREATER_MSG = ' cannot be lesser than ';
const EQUAL = 'equal';
const EQUAL_MSG = ' should be equal to ';
const ARRAY = 'array';
const ARRAY_MSG = ' cannot be empty';

export const isPresent = (val) =>  typeof val === 'undefined' ? false : true

export const isString = (val) =>  typeof val === 'string' ? true : false;

export const email = (val) => {
    // var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return re.test(val);
}

export const isDate = val => {
    var m = moment(val);
    // console.log(m)
    // TODO: do the actual date validation
    return m._isAMomentObject
    // return m.isValid()
}

export const isGreater = (val, compared_val, type) => {
    if(typeof type == 'undefined' || type === "number") {
        return val > compared_val
    } else {
        return moment(val).isAfter(moment(compared_val))
    }
}

export const isEqual = (val, compared_val, type) => {
    if(typeof type == 'undefined' || type === "number") {
        return val === compared_val
    } else {
        return moment(val).isSame(moment(compared_val))
    }
}

export const isNumeric = (val) => !isNaN(val)

export default class Validator {
    
    constructor(data, validation){

        if(!Array.isArray(validation)) {
                throw new Error("an array is required!");
        }

        this.data = data
        this.validation = validation

        this.isValid = true;
        this.errors = {};
    }

    isValid() {
        return this.isValid
    }

    validate() {
        let self = this;
        for(let i = 0; i < this.validation.length; i++) {
            let val = this.data[this.validation[i].field];
            for(let x = 0; x < this.validation[i].rules.length; x++ ) {
                let rules = this.validation[i].rules[x].split("|");
                console.log(rules)
                switch (rules[0]) {
                    case REQUIRE:
                        if (!isPresent(val))
                            addError(REQUIRE_MSG)
                        break;
                    case STRING:
                        if(!isString(val))
                            addError(STRING_MSG)
                        break
                    case EMAIL:
                        if (!email(val))
                            addError(EMAIL_MSG)
                        break
                    case NUMBER:
                        if (!isNumeric(val)) 
                            addError(NUMBER_MSG)
                        break
                    case DATE:
                        if(!isDate(val))
                            addError(DATE_MSG)
                        break
                    case ARRAY:
                        // console.log(val)
                        // console.log(!Array.isArray(val))
                        // console.log(Array.isArray(val))
                        // console.log( !val.length)
                        if(Array.isArray(val) && !val.length){
                            addError(ARRAY_MSG) 
                        }


                        // console.log(val, "random")
                            // addError(ARRAY_MSG)
                        break
                    case GREATER:
                        let t = this.validation[i].rules.includes("date") ? "date" : "number"
                        let compared_val = this.data[rules[1]];
                        if(!isGreater(val, compared_val, t)) {
                            let msg = GREATER_MSG + rules[1];
                            addError(msg)
                        }
                        break
                    case EQUAL:
                        let e = this.validation[i].rules.includes("date") ? "date" : "number"
                        let e_compared_val = this.data[rules[1]];
                        if(!isEqual(val, e_compared_val, e)) {
                            let msg = EQUAL_MSG + rules[1];
                            addError(msg)
                        }
                        break
                    default:
                        break;
                }
            }

            function addError(_mes){
                console.log( self.validation[i])
                let mes =  self.validation[i].field + _mes;
                if(self.errors[self.validation[i].field])
                    self.errors[self.validation[i].field].push(mes)
                else
                    self.errors[self.validation[i].field] = new Array(mes)
            }
        }
        
        if(Object.keys(this.errors).length > 0){
            this.isValid = false;
        }
            
        
        return this.errors;
    }

    
}