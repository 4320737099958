import React, { Component } from 'react';
import '../../../asset/css/common/datetime.css';
import * as Datetime from 'react-datetime';


class DateTime extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            hasValue: props.value ? true : false,
        }
        this._handleDateTimeValue = this._handleDateTimeValue.bind(this);
    }

    toggleActiveState = () => {
        this.setState({
            active: !this.state.active
        });
    }

    _handleDateTimeValue (e) {
        this.setState({
            hasValue : e._isValid,
        });

        this.toggleActiveState()
    }

    render() {
        return (
            <div>
                <label className="label">{ this.props.label || '' }</label>
                <div className={`DateTime-label ${this.state.active ? ' active' : ''} ${!this.props.icon ? ' full-width' : ''}`}>
                    <Datetime className="date" value={this.props.value} dateFormat={this.props.dateFormat || "YYYY-MM"} timeFormat={ this.props.timeFormat || false} onFocus={this.toggleActiveState} onBlur={this._handleDateTimeValue} onChange={this.props.onChange} />
                    <span className="icon">
                        { this.props.icon ? <img src={this.props.icon} alt="user" width="18" height="20" /> : '' }
                    </span>
                    <small className="Helper">
                        <font>{ this.props.error || ""}</font>
                    </small>
                </div>
            </div>
        );
    }
}

export default DateTime;