import React, {Component} from 'react';
import { connect } from "react-redux";
import { Route, Redirect} from 'react-router'

class AdminGuard extends Component {
    constructor({ component: Component, ...rest }){
        super()
        this.component = Component;
        this.rest = {...rest}
    }

    render() {
        return (
            <Route {...this.rest} render={(props) => (
                this.props.isAuthenticated === true
                  ? this.props.isVerified ? <this.component {...props} />
                  : <Redirect to={{ pathname: '/admin-verification', state: { from: props.location } }} />
                  : <Redirect to={{ pathname: '/admin-login', state: { from: props.location } }} />
              )} />
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.user.isAdminAuthenticated,
        isVerified: state.user.isAdminVerified
    };
}

const mapDispatchToProps = disptch => {
    return {}
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AdminGuard);