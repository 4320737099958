import React, { Component } from 'react';
import SliderPage from '../templates/SliderPage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from '../components/slider';
import '../../asset/css/home.css';
// import Line from '../../asset/images/line.svg';
import PayCentreSvg from '../../asset/images/paycentresvg.svg';
import PayCentreLogo from '../../asset/images/paycentre-logo.svg';
import PayPadSvg from '../../asset/images/paypadsvg.svg';
import PayPadLogo from '../../asset/images/paypad-logo.png';
// import CashEnvoySvg from '../../asset/images/cashenvoysvg.svg';
// import CashEnvoyLogo from '../../asset/images/cashenvoy-logo.svg';
import RightArrow from '../../asset/images/right-arrow.svg';
import BlogImage1 from '../../asset/images/blog-1.png';
import BlogImage2 from '../../asset/images/blog-2.png';
import BlogImage3 from '../../asset/images/blog-3.png';
import Container from 'react-bootstrap/Container';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import CookieConsent from 'react-cookie-consent';
import "./anim.css";
import ScrollAnimation from 'react-animate-on-scroll';

class Home extends Component {

  constructor(props) {
    super(props)

    this._gotoBlog = this._gotoBlog.bind(this)
  }

  _gotoBlog() {
    window.location.href = 'https://blog.esettlementgroup.com'
  }

  render(){

    return (
        <ParallaxProvider>
          <SliderPage>
            <div style={{width:'100%', backgroundColor: '#ccc'}}>
              <Slider/>
            </div>
          
            <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
              <section className="main-home-section" id="product">
                <div className="py-4">
                  <br/><br/><br/>
                  <Container>
                    <h4 className="heading">Our <span className="headingBold">Products</span></h4>
                    <Row>
                      <Col xs={12} md={5}>
                        {/* <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'> */}
                          <img src={PayCentreSvg} alt="PayCentre SVG" className="paycentreSvg" />
                        {/* </ScrollAnimation> */}
                      </Col>
                      <Col xs={12} md={7}>
                      
                        <img src={PayCentreLogo} alt="PayCentre Logo" className="paycenterLogo mt-2" />
                        <p className="mt-3">
                          The PayCentre Web and Mobile app grants you the freedom to receive and provide banking hall services by offering you the best agency banking solution in Africa.
                        </p>
                        
                        <p>Our easy to use platform grants you total control and insight into your business performance. </p>

                        <a href="http://paycentreafrica.com/" target="blank" className="visit-button">
                          Visit PayCentre
                        </a>
                      </Col>
                    </Row>
                  </Container>
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>
              </section>
            </Parallax>


            <section className="main-home-section">
              <div className="py-4 light-blue-bg">
                <br/><br/><br/>
                <Container>
                  <Row>
                    <Col xs={12} md={5}>
                      <img src={PayPadSvg} alt="PayCentre SVG" className="payPadSvg" />
                    </Col>
                    <Col xs={12} md={7}>
                      <img src={PayPadLogo} alt="PayCentre Logo" className="payPadLogo mt-4" />
                      {/* <ScrollAnimation animateIn='wobble' duration={1.5} animateOut="fadeOut" delay={1}> */}
                        <p className="mt-3">
                          PAYPAD is an mPOS solution that enables businesses receive payments via a smart phone. PayPad grants its users access to functions such as E-marketing, smart printing, sales analytics, payment acceptance, seamless integration, multi-location support, invoice & order issuance
                        </p>
                      {/* </ScrollAnimation> */}
                      <a href="http://paypad.com.ng/" target="blank" className="visit-button">
                        Visit PayPad
                      </a>
                    </Col>
                  </Row>
                </Container>
                <br/><br/><br/><br/><br/><br/><br/><br/>
              </div>
            </section>
            
                {/* <div className="py-4">
                  <Container>
                    <Row>
                      <Col xs={12} md={5}>
                        <img src={CashEnvoySvg} alt="CashEnvoy SVG" className="cashEnvoySvg" />
                      </Col>
                      <Col xs={12} md={7}>
                        <img src={CashEnvoyLogo} alt="CashEnvoy Logo" className="cashEnvoyLogo mt-4" />
                        <p className="mt-3">
                        CashEnvoy is a payment service that allows businesses receive payment online. Websites that integrate with CashEnvoy can accept payment from all the major Nigerian debit cards, international Visa/Mastercards and also from the CashEnvoy wallet.
                        </p>

                        <a href="http://cashenvoy.com/" target="blank" className="visit-button">
                          Visit CashEnvoy
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </div>        */}
              

            <section className=" py-4" id="blog">
              <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
              <Container>
                <Col xs={12}>
                  <div className="blog-section-heading">
                    <h4 className="heading">From Our <span className="headingBold">BLog</span></h4>
                    <p>Stay abreast with latest news, press related material, announcements and other relevant information.</p>
                  </div>
                </Col>

                <br/><br/>

                <Row className="blog-row">
                  <Col xs={12} md={4} className="blog-card-col py-3 d-flex align-items-stretch">
                    {/* <ScrollAnimation animateIn="bounceInLeft" animateOut="fadeOut" duration={1.8} > */}
                      <div className="blog-card">
                        <div className="blog-card-head">
                          <img src={BlogImage1} alt="Blog_Image_1" className="blog-image" />
                        </div>
                        <div className="blog-card-body">
                          <h4 className="blog-card-date">January 22, 2020</h4>
                          <h4 className="blog-card-title">Reduced charges and 20% bonus on all Funds Transfer Transactions</h4>
                          <p className="blog-card-bodytext">Our charges on funds transfer transactions have been reviewed to help you make more profit in your business...</p>
                          
                          <div className="blog-button-area">
                            <button className="blog-button" onClick={this._gotoBlog}>
                              <img src={RightArrow} alt="Right Arrow" />
                            </button>
                          </div>

                        </div>
                      </div>
                    {/* </ScrollAnimation> */}
                  </Col>

                  <Col xs={12} md={4} className="blog-card-col my-3 d-flex align-items-stretch">
                    {/* <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'> */}
                      <div className="blog-card">
                        <div className="blog-card-head">
                          <img src={BlogImage2} alt="Blog_Image_1" className="blog-image" />
                        </div>
                        <div className="blog-card-body">
                          <h4 className="blog-card-date">June 18, 2019</h4>
                          <h4 className="blog-card-title">PayCentre listed among the 2019 inclusive Fintech 50</h4>
                          <p className="blog-card-bodytext">Leading agent banking solution provider, PayCentre Africa, has emerged as a winner in the 2019 Inclusive Fintech 50 list....</p>
                          
                          <div className="blog-button-area">
                            <button className="blog-button" onClick={this._gotoBlog}>
                              <img src={RightArrow} alt="Right Arrow" />
                            </button>
                          </div>

                        </div>
                      </div>
                    {/* </ScrollAnimation> */}
                  </Col>

                  <Col xs={12} md={4} className="blog-card-col my-3 d-flex align-items-stretch">
                    {/* <ScrollAnimation animateIn="bounceInRight" animateOut="fadeOut" duration={1.8} > */}
                      <div className="blog-card">
                        <div className="blog-card-head">
                          <img src={BlogImage3} alt="Blog_Image_1" className="blog-image" />
                        </div>
                        <div className="blog-card-body">
                          <h4 className="blog-card-date">JUNE 13, 2019</h4>
                          <h4 className="blog-card-title">How to make money in the PayCentre Agency Network</h4>
                          <p className="blog-card-bodytext">Agent banking is a system in which individuals are equipped with tools that let them carry out banking hall transactions...</p>
                          
                          <div className="blog-button-area">
                            <button className="blog-button" onClick={this._gotoBlog}>
                              <img src={RightArrow} alt="Right Arrow" />
                            </button>
                          </div>

                        </div>
                      </div>
                    {/* </ScrollAnimation> */}
                  </Col>
                </Row>
              </Container>
              <CookieConsent
                expires={1}
                style={{textAlign: "center",backgroundColor: "#fff",color:"black"}}
                buttonText="Accept"
                buttonStyle={{marginRight:"40px", backgroundColor:"#007bff",color:"white", borderRadius:"2px"}}
              >
              By using this site you agree to our  <a className="cookieTag" style={{color:""}} href="/cookie-policy  "> cookie policy.</a>   Please accept these before using the site.
            </CookieConsent>
            </section>
          
        </SliderPage>
        </ParallaxProvider>
    );
  }
  
}

export default Home;
