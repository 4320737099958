import React, { Component } from 'react';
import '../../../asset/css/common/button.css';
import EllipsisLoader from './ellipsis-loader';

class button extends Component {
    render() {
        return (
            <div>
                <button type="button" disabled={this.props.loading} className="primary-button" onClick={this.props.onClick}>
                    {
                        this.props.icon ? 
                        <img src={this.props.icon} alt="icon" />
                        : ""
                    }
                    <span className="submit-button-text"> 
                    {this.props.loading ?
                        <EllipsisLoader/> :
                        this.props.text || "SUBMIT"
                    }
                    </span>
                </button>
            </div>
        );
    }
}

export default button;