import http from './http';

export const CreateCV = async data => {
    try {
        return await http.post('cv/create', data)
    }  catch (err) {
        throw  err;
    }
}

export const FetchCV = async user_id => {
    const url = 'cv/fetch' + (typeof user_id === 'undefined' ? '' : '/' + user_id);
    try {
        return await http.get(url)
    }  catch (err) {
        throw  err;
    }
}

export const UploadCVFile = async data=> {
    try {
        http.defaults.headers.post['Content-Type'] = 'multipart/form-data'
        http.defaults.headers.common['Accept'] = '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        return await http.post('cv/upload', data)

    }  catch (err) {
        throw  err;
    }
}


export const UploadAvatarFile = async (data) =>{
      try {
        http.defaults.headers.post['Content-Type'] = 'multipart/form-data'
        http.defaults.headers.common['Accept'] = 'images/*'
        return await http.post('profile/upload/avatar', data)

    }  catch (err) {
        throw  err;
    }  
}

export const DeleteComponent = async (comp, id) => {
    const url = 'cv/' + comp + '/delete/' + id;
    try {
        return await http.delete(url)
    }  catch (err) {
        throw  err;
    }
}
export const DeleteApplication = async (id) => {
    const url = 'work/application/remove/' + id;
    try {
        return await http.post(url)
    }  catch (err) {
        throw  err;
    }
}

 
