import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseURL = "https://career.esettlementgroup.com/api/v1"; // production
// const baseURL = "https://cors-anywhere.herokuapp.com/https://career.esettlementgroup.com/api/v1"; // production
// const baseURL = 'http://104.236.35.57:9090/api/v1'; // staging
// const baseURL = 'http://127.0.0.1:8000/api/v1'; // dev

let http = axios.create({
	baseURL: baseURL,
});

http.interceptors.request.use(
	(config) => {
		config.headers["Content-Type"] = config.headers["Content-Type"] || "application/json";
		config.headers["Accept"] = config.headers["Accept"] || "application/json";
		let token =
			JSON.parse(localStorage.getItem("rt")) || JSON.parse(localStorage.getItem("rta"));
		if (token) {
			config.headers.authorization = "Bearer " + token;
		}
		return config;
	},
	(error) => {}
);

http.interceptors.response.use(
	(response) => {
		// console.log(response)
		if (response.config.method === "post") {
			toast(response.data.message);
		}
		return response;
	},
	(error) => {
		const {
			response: {
				data: { message },
			},
		} = error;
		//console.dir(error);
		toast.error(message);
		let status = error.response.status;
		let data = error.response.data;
		data.status = status;
		throw data;
	}
);

export default http;
