import React, { Component } from 'react';
// import Container from 'react-bootstrap/Container';
import AppNav from '../components/nav';
import Footer from '../components/footer.js';
// import Vacancy from '../components/vacancy.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../asset/css/App.css';



class SliderPage extends Component {
  render() {
    return (
      <div>
        <AppNav/>
        
        {/* <Container fluid={true} > */}
            { this.props.children }
        {/* </Container> */}
        <Footer/>
      </div>
    );
  }
}
   

export default SliderPage;