import React, { Component } from 'react';
import '../../../asset/css/common/input.css';


class Input extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            hasValue: props.value ? true : false,
        }
        this._handleInputValue = this._handleInputValue.bind(this);
    }

    toggleActiveState = () => {
        this.setState({
            active: !this.state.active
        });
    }

    _handleInputValue (e) {
        let value = e.target.value;
        
        this.setState({
            hasValue : value.trim() !== "" ? true : false,
        });

        this.toggleActiveState()
    }

    render() {
        //console.log(this._handleInputValue());
        return (
            <div>
                <label className="label" dangerouslySetInnerHTML={{__html: this.props.label || '' }}></label>
                <div className={this.state.active ? "Input-label active" : "Input-label"}>
                    <input 
                        value={this.props.value} type={this.props.type || "text"} 
                        onFocus={this.toggleActiveState} 
                        onBlur={this._handleInputValue} 
                        onChange={this.props.onChange} 
                        name={this.props.name} 
                        placeholder={this.props.placeholder}
                        disabled={this.props.disabled}
                    />
                    <span className="icon">
                        { this.props.icon ? <img src={this.props.icon} alt="user" width="18" height="20" /> : '' }
                    </span>
                    <small className="Helper">
                        <font>{ this.props.error || ""}</font>
                    </small>
                </div>
            </div>
        );
    }
}

export default Input;