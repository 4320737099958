import http from './http';

export const CreateJobType = async data => {
    try {
        return await http.post('work/job-type/create', data)
    }  catch (err) {
        throw  err;
    }
}

export const CreateDepartment = async data => {
    try {
        return await http.post('work/department/create', data)
    }  catch (err) {
        throw  err;
    }
}

export const CreateJob = async data => {
    try {
        return await http.post('work/job/create', data)
    }  catch (err) {
        throw  err;
    }
}

export const FetchJobType = async () => {
    try {
        return await http.get('work/job-type')
    }  catch (err) {
        throw  err;
    }
}

export const FetchDepartment = async () => {
    try {
        return await http.get('work/department')
    }  catch (err) {
        throw  err;
    }
}

export const FetchJob = async () => {
    try {
        return await http.get('work/job')
    }  catch (err) {
        throw  err;
    }
}

export const CanApply = async () => {
    try {
        return await http.get('work/application/possible')
    }  catch (err) {
        throw  err;
    }
}

export const Apply = async data => {
    try {
        return await http.post('work/application/create', data)
    }  catch (err) {
        throw  err;
    }
}