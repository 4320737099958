import React, {Component} from 'react';
import { connect } from "react-redux";
import { Route, Redirect} from 'react-router'

class LoginGuard extends Component {
    constructor({ component: Component, ...rest }){
        super()
        this.component = Component;
        this.rest = {...rest}
    }

    render() {
        return (
            <Route {...this.rest} render={(props) => (
                this.props.isAuthenticated !== true
                ? <this.component {...props} />
                : <Redirect to={{ pathname: '/profile', state: { from: props.location } }} />
              )} />
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.user.isAuthenticated,
        isVerified: state.user.isVerified
    };
}

const mapDispatchToProps = disptch => {
    return {}
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(LoginGuard);