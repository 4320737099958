import React, { Component } from "react";
import EllipsisLoader from "./ellipsis-loader";
import "../../../asset/css/common/loader.css";

class EllipsisLoaderForSuspense extends Component {
	render() {
		return (
			<div className="suspense__loader__container">
				<EllipsisLoader />
			</div>
		);
	}
}

export default EllipsisLoaderForSuspense;
