import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./asset/css/index.css";

import EllipsisLoaderForSuspense from "./ui/components/common/EllipsisLoaderForSuspense";

import Home from "./ui/pages/home";
// import HomeCopy from "./ui/pages/home-copy";
// import Contact from "./ui/pages/contact";
// import Career from "./ui/pages/career";
// import Registration from "./ui/pages/registration";
// import Login from "./ui/pages/login";
// import ForgotPassword from "./ui/pages/forgotpassword";
// import ResetPassword from "./ui/pages/resetpassword";
// import Jobs from "./ui/pages/jobs";
import Personaldetails from "./ui/pages/personaldetails.js";
// import JobOpening from "./ui/pages/jobopenings.js";
// import JobApplications from "./ui/pages/application";
// import AdminDashboard from "./ui/admin/home.js";
// import AdminJobs from "./ui/admin/jobs.js";
// import AdminApplicantDetails from "./ui/admin/applicant-details.js";
// import QuestionPool from "./ui/admin/question-pool.js";
// import Applications from "./ui/admin/applications.js";
// import AdminRegistration from "./ui/admin/registration.js";
// import AdminLogin from "./ui/admin/login.js";
// import AdminEmailVerification from "./ui/admin/emailVerification.js";
// import AdminForgotPassword from "./ui/admin/forgotPassword.js";
// import AdminVerification from "./ui/admin/verification.js";
import Verify from "./ui/pages/verify.js";
// import Verified from "./ui/pages/verified.js";
// import AdminResetPassword from "./ui/admin/resetPassword.js";
// import ShortListed from "./ui/admin/shortlisted";
// import Settings from "./ui/admin/settings.js";
import * as serviceWorker from "./serviceWorker";
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import AuthGuard from "./guards/AuthGuard";
import LoginGuard from "./guards/LoginGuard";
import AdminGuard from "./guards/AdminGuard";
import store from "./redux/store";

const history = createBrowserHistory();

// const Home = lazy(() => import("./ui/pages/home"));
// const Personaldetails = lazy(() => import("./ui/pages/personaldetails.js"));
const ResetPassword = lazy(() => import("./ui/pages/resetpassword"));
const ForgotPassword = lazy(() => import("./ui/pages/forgotpassword"));
const Login = lazy(() => import("./ui/pages/login"));
const Registration = lazy(() => import("./ui/pages/registration"));
const Verified = lazy(() => import("./ui/pages/verified"));
const Jobs = lazy(() => import("./ui/pages/jobs"));
const HomeCopy = lazy(() => import("./ui/pages/home-copy"));
const JobApplications = lazy(() => import("./ui/pages/application"));
const Contact = lazy(() => import("./ui/pages/contact"));
const Career = lazy(() => import("./ui/pages/career"));
const JobOpening = lazy(() => import("./ui/pages/jobopenings.js"));
const Cookie = lazy(() => import("./ui/pages/cookie"));
const Conditions = lazy(() => import("./ui/pages/conditions"));
const Refunds = lazy(() => import("./ui/pages/refund"));
const PaymentTerms = lazy(() => import("./ui/pages/paymentTerms.js"));

const Privacy = lazy(() => import("./ui/pages/privacy"));

/*Admin pages with Lazy loading */
/* - - - - - - - - - - - - - - - - - - - - - */

const AdminDashboard = lazy(() => import("./ui/admin/home"));
const AdminJobs = lazy(() => import("./ui/admin/jobs"));
const AdminApplicantDetails = lazy(() => import("./ui/admin/applicant-details"));
const Applications = lazy(() => import("./ui/admin/applications"));
const QuestionPool = lazy(() => import("./ui/admin/question-pool"));
const Settings = lazy(() => import("./ui/admin/settings"));
const AdminRegistration = lazy(() => import("./ui/admin/registration"));
const AdminLogin = lazy(() => import("./ui/admin/login"));
const AdminEmailVerification = lazy(() => import("./ui/admin/emailVerification"));
const AdminForgotPassword = lazy(() => import("./ui/admin/forgotPassword"));
const AdminVerification = lazy(() => import("./ui/admin/verification"));
const AdminResetPassword = lazy(() => import("./ui/admin/resetPassword"));
const ShortListed = lazy(() => import("./ui/admin/shortlisted"));

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			{/* <Route path="/contact" exact={true} component={Contact}></Route>
      <Route path="/career" component={Career} exact={true}></Route>
      <Route path="/jobopenings" component={JobOpening} exact={true}></Route>
      <Route path="/verify" component={Verify}></Route> */}
			<Route path="/" exact={true} component={Home}></Route>
			{/* <Route path="/verified/:id/:hash/:expires/:signature" component={Verified}></Route> */}
			{/* <LoginGuard path="/registration" component={Registration}></LoginGuard> */}
			{/* <Route path="/successful" component={Successful}></Route> */}
			{/* <Route path="/login" component={Login}></Route> */}
			{/* <LoginGuard path="/forgot-password" component={ForgotPassword}></LoginGuard> */}
			{/* <Route path="/reset-password/:token/:email" component={ResetPassword}></Route> */}
			<AuthGuard path="/preview" component={Personaldetails}></AuthGuard>
			<AuthGuard path="/profile" component={Personaldetails}></AuthGuard>

			{/* react lazy load */}
			{/* <Suspense fallback={<div>Loading...</div>} */}
			<Suspense fallback={<EllipsisLoaderForSuspense />}>
				<Switch>
					{/* <AuthGuard path="/preview" component={Personaldetails}></AuthGuard> */}
					{/* <AuthGuard path="/profile" component={Personaldetails}></AuthGuard> */}
					<Route path="/reset-password/:token/:email" component={ResetPassword}></Route>
					<LoginGuard path="/forgot-password" component={ForgotPassword}></LoginGuard>
					<Route path="/login" component={Login}></Route>
					<LoginGuard path="/registration" component={Registration}></LoginGuard>
					<Route
						path="/verified/:id/:hash/:expires/:signature"
						component={Verified}
					></Route>
					{/* - - - - - - - - - - - - - */}
					<Route path="/job-details" component={Jobs}></Route>
					<Route path="/cookie-policy" component={Cookie}></Route>
					<Route path="/privacy-policy" component={Privacy}></Route>
					<Route path="/refund-policy" component={Refunds}></Route>
					<Route path="/payment-terms-condition" component={PaymentTerms}></Route>
					<Route path="/terms-condition" component={Conditions}></Route>
					{/* <Route path="/" exact={true} component={Home}></Route> */}
					<Route path="/home-copy" exact={true} component={HomeCopy}></Route>
					<Route path="/contact" exact={true} component={Contact}></Route>
					<Route path="/career" component={Career} exact={true}></Route>
					<Route path="/jobopenings" component={JobOpening} exact={true}></Route>
					<AuthGuard path="/applications" component={JobApplications}></AuthGuard>

					{/* New Admin Routes with Lazy Loading */}
					{/* - - - - - - - - - - - - - */}

					<AdminGuard path="/admin-dashboard" component={AdminDashboard}></AdminGuard>
					<AdminGuard path="/admin-jobs" component={AdminJobs}></AdminGuard>
					<AdminGuard
						path="/admin-applicant-details"
						component={AdminApplicantDetails}
					></AdminGuard>
					<AdminGuard path="/admin-applications" component={Applications}></AdminGuard>
					<AdminGuard path="/admin-question-pool" component={QuestionPool}></AdminGuard>
					<AdminGuard path="/admin-settings" component={Settings}></AdminGuard>
					<Route path="/admin-registration" component={AdminRegistration}></Route>
					<Route path="/admin-login" component={AdminLogin}></Route>
					<Route
						path="/admin-email-verification"
						component={AdminEmailVerification}
					></Route>
					<Route path="/admin-forgot-password" component={AdminForgotPassword}></Route>
					<Route path="/admin-verification" component={AdminVerification}></Route>
					<Route path="/admin-reset-password" component={AdminResetPassword}></Route>
					<AdminGuard path="/admin-shortlisted" component={ShortListed}></AdminGuard>
				</Switch>
			</Suspense>

			{/*Previous Admin Routes */}
			{/* <AdminGuard path="/admin-dashboard" component={AdminDashboard}></AdminGuard>
			<AdminGuard path="/admin-jobs" component={AdminJobs}></AdminGuard>
			<AdminGuard
				path="/admin-applicant-details"
				component={AdminApplicantDetails}
			></AdminGuard>
			<AdminGuard path="/admin-applications" component={Applications}></AdminGuard>
			<AdminGuard path="/admin-question-pool" component={QuestionPool}></AdminGuard>
			<AdminGuard path="/admin-settings" component={Settings}></AdminGuard>
			<Route path="/admin-registration" component={AdminRegistration}></Route>
			<Route path="/admin-login" component={AdminLogin}></Route>
			<Route path="/admin-email-verification" component={AdminEmailVerification}></Route>
			<Route path="/admin-forgot-password" component={AdminForgotPassword}></Route>
			<Route path="/admin-verification" component={AdminVerification}></Route>
			<Route path="/admin-reset-password" component={AdminResetPassword}></Route>
			<AdminGuard path="/admin-shortlisted" component={ShortListed}></AdminGuard> */}
		</Router>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
