import { CanApply } from '../../services/job'
import { selJob } from '../reducers/job'; 

export const nextStage = () => {

    CanApply().then( res => {
        if(Object.values(selJob).length > 0 && res.data.data.canApply) {
            window.location.href = "/preview"
        } else {
            window.location.href = "/profile";
        }
        
    }).catch( err => {
        window.location.href = "/profile";
    })
}

export const dispatchJob = res => ({type: 'SELECT_JOB', job: res})